<template>
    <div class="action-open-chat-instant">
        <div class="title" v-html="title" />
        <div class="divider"></div>
        <div class="footer">
            <div class="footer-title">임시 대화방 만료 기간</div>
            <div>- 임시 대화방은 <b>3일 후</b> 자동 만료됩니다.</div>
            <div>- 추가 소개 시 다시 <b>3일</b>로 늘어나며, 소개팅 성사 시 <b>14일</b>로 늘어납니다.</div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'ActionOpenChatInstant',
    props: ['message'],
    computed: {
        chat() {
            return this.$store.getters.chat
        },
        chatUser() {
            return this.chat.user || {}
        },
        title() {
            return this.$translate('ACTION_OPEN_CHAT_INSTANT_TITLE_RECOMMENDED').replace(
                /%s/,
                this.$nameOrNick(this.chatUser)
            )
        },
    },
    methods: {
        async onClickProfile() {
            try {
                await this.$store.dispatch('loadIntroducingUser', this.requestor.id)
                this.$store.commit('setShowFriendsFilter', false)
            } catch (e) {
                this.$toast.error(e.data)
            }
        },
    },
}
</script>

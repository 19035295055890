<template>
    <div class="action-open-chat">
        <ActionOpenChatNormal :message="message" v-if="friendType === 1" />
        <ActionOpenChatInstant :message="message" v-if="friendType !== 1" />
    </div>
</template>
<script>
import ActionOpenChatNormal from './ActionOpenChatNormal'
import ActionOpenChatInstant from './ActionOpenChatInstant'

export default {
    name: 'ActionOpenChat',
    props: ['message'],
    components: {
        ActionOpenChatNormal,
        ActionOpenChatInstant,
    },
    computed: {
        friendType() {
            return this.$options.filters.friendType(this.$store.getters.chat.user, true)
        },
    },
}
</script>
